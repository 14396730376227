<template>
  <div class="childAccount app-container">
    <Eheader
      :sup_this="sup_this"
      :query="query"
      :formField="formField"
      @changeChildHandel="changeChildHandel"
    ></Eheader>
    <el-row class="checkBoxClass">
      <el-col :span="1">
        <el-checkbox v-model="checkedAll" @change="changeCheckAll" :indeterminate="indeterminate" class="checkedAll"
          >全选</el-checkbox
        >
      </el-col>
      <el-col :span="2">
        <batchCancelBtn
          :checkedAll="checkedAll"
          :multipleSelection="multipleSelection"
          :clearArr="clearArr"
          :allData="allData"
          :btnLonding="btnLonding"
          :sup_this="sup_this"
        ></batchCancelBtn>
      </el-col>
    </el-row>
    <CommonTable
      height="auto"
      :cols="cols"
      :infoData="data"
      ref="tableRef"
      :span-method="spanMethod"
      @selection-change="handleSelectionChange"
      @select="selectHandler"
      @select-all="selectAllHandler"
      :cell-class-name="cellClassName"
      v-loading="tableLoading"
    >
      <template #mergeSlot="{ scoped }">
        <div class="merge-slot-class">
          <el-image style="width: 60px; height: 60px" :src="figure(scoped)" fit="contain"></el-image>
          <div>
            <div style="max-width: 200px">
              {{ scoped.name || '暂无名字' }}
            </div>
          </div>
        </div>
      </template>
      <!-- 原型分类 -->
      <template #CategorySlot="{ scoped }">
        {{ (scoped.productCategory && scoped.productCategory.name) || '暂无' }}
      </template>
      <!-- 款式 -->
      <template #structSlot="{ scoped }">
        <el-tag
          type="primary"
          :key="id"
          v-for="{ name, status, id } in structs(scoped) || []"
          style="margin-right: 5px; margin-bottom: 5px"
        >
          {{ name }}
          <color-text-btn v-if="isShelves({ status })" type="danger">(已上架) </color-text-btn>
        </el-tag>
      </template>
      <!--  适用人群列 -->
      <template #sexSlot="{ scoped }">
        <el-tag type="primary">
          {{ SEX_LIST[scoped[scoped.prop] || 0] }}
        </el-tag>
      </template>

      <!--  适用季节列-->
      <template #seasonSlot="{ scoped }">
        <el-tag type="primary">
          {{ SEASONS_LSIT[scoped[scoped.prop] || 0] }}
        </el-tag>
      </template>
      <!-- 尺码列 -->
      <template #sizesSlot="{ scoped }">
        <el-tag
          type="primary"
          :key="id"
          v-for="{ sizeName, id, psdPath, status } in sizeList(scoped)"
          style="margin-right: 5px; margin-bottom: 5px"
        >
          {{ sizeName }}
          <color-text-btn v-if="isShelves({ status })" type="danger">(已上架) </color-text-btn>
          <color-text-btn type="danger" v-if="!psdPath">(psd未上传)</color-text-btn>
        </el-tag>
      </template>
      <!-- 参考尺码  -->
      <template #baseSizesSlot="{ scoped }">
        <el-tag type="primary" v-if="scoped.referSize">
          {{ scoped.referSize && scoped.referSize.sizeName }}
        </el-tag>
      </template>
      <!-- 创建日期 -->
      <template #createTimeSlot="{ scoped }">
        <span>{{ parseTime(scoped[scoped.prop]) }}</span>
      </template>
      <template #operationSlot="{ scoped: { row } }">
        <ColorTextBtn @click="cancelClick(row)" :loading="cancelBtnLoading && row.id === clickId"
          >取消分配</ColorTextBtn
        >
      </template>
    </CommonTable>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
  </div>
</template>

<script>
import Eheader from './module/header.vue'
import { formField } from './module/formField.js'
import { tableCol as cols } from './module/tableCols.js'
import { getAllData } from '../api.js'
import { getValueFromObj, parseImgSrc, parseTime } from '@/utils'
import { initDataMixin } from '@/mixins'
import { deepClone } from '@/components/avue/utils/util'
import { SEX_LIST, SEASONS_LSIT, SHELVES } from '@/utils/constant'
import batchCancelBtn from './module/batchCancalBtn.vue'
import { assignData } from '../api.js'
export default {
  mixins: [initDataMixin],
  components: { Eheader, batchCancelBtn },
  props: {
    isMultipleReferSize: {
      type: Boolean,
      default: true
    },
    referSizeSpanCol: {
      type: Array,
      default: () => ['参考尺码', '尺码']
    }
  },
  data() {
    return {
      SHELVES,
      SEX_LIST,
      SEASONS_LSIT,
      sup_this: this,
      formField,
      cols,
      url: '/externaladmin/prototypeService/productPrototype/list',
      checkedAll: false,
      // tableRef 引用
      tableRef: {},
      // 选择的列表
      multipleSelection: [],
      //删除的列表
      clearArr: [],
      dataFilterForm: {
        structName: '',
        psdStatus: ''
      },
      // 获取的所有数据
      allData: [],
      btnLonding: true,
      query: {
        childrenIdList: [1]
      },
      clickId: null,
      cancelBtnLoading: false
    }
  },
  computed: {
    // indeterminate，控制全选按钮上的 一杠是否显示
    indeterminate() {
      if (this.checkedAll) {
        // 当全选按钮选中时，看看this.clearArr里有没有东西，有的话标识有东西没有选中，则返回true 没有就返回false
        return !!this.clearArr.length
      }
      // 当全选按钮没有选中时，this.multipleSelection 里有无我们选中的东西，如果有的话还需要选中东西的长度不能等于所有数据的
      // 长度，如果满足就返回true，不满足就返回false
      return !!this.multipleSelection.length && this.multipleSelection.length != this.total
    },
    // 图片的路径如果没有就默认路径
    figure() {
      return (row) => {
        let src = getValueFromObj(row, 'styleList[0].styleDisplayImageList[0].displayImagePath')
        return src ? parseImgSrc(src) : require('@/assets/images/default.png')
      }
    },
    // 判断是否上架
    isShelves() {
      return (data) => {
        return data.status == SHELVES
      }
    },
    // 返回所有款式
    structs({ dataFilterForm: { structName } }) {
      return (row) => {
        return (row.styleList || []).filter(({ name }) => {
          if (!structName) return true
          structName.lastIndex = 0
          return structName.test(name)
        })
      }
    },
    // 返回尺寸列表
    sizeList({ dataFilterForm: { psdStatus } }) {
      if (![undefined, null].includes(psdStatus)) psdStatus = psdStatus + ''
      return (row) => {
        let sizeList = row[row.prop]
        sizeList = sizeList || []
        return sizeList.filter(({ psdPath }) => {
          if (!psdStatus) return true
          if (psdStatus === '0') {
            return !psdPath
          } else if (psdStatus === '1') {
            return psdPath
          }
        })
      }
    }
  },
  watch: {
    // 监听全选按钮的变化
    checkedAll(newVal) {
      if (this.allData.length) {
        this.clearArr = [] // 当全选未false时，里面还有东西，但我们用不上
        this.multipleSelection = [] // 当全选未时true，里面还有东西，但我们用不上
      }
      // 也可以不用判断当check All改变直接把这两个数组重置就行
    },
    // 监听选中的数据的变化
    multipleSelection: {
      handler(newVal) {
        // 如果选中的数据长度等于 所有数据总长度，就把全选按钮改为true
        if (this.multipleSelection.length == this.total) {
          this.checkedAll = true
        }
        console.log('multipleSelection', newVal)
      },
      deep: true
    },
    clearArr: {
      handler(newVal) {
        if (newVal.length === this.allData.length) {
          this.checkedAll = false
        }
        console.log('clearArr', newVal)
      },
      deep: true
    },
    selectedData: {
      handler(newVal) {
        console.log('newVal', newVal)
      }
    }
  },
  methods: {
    parseTime,
    // 获取所有数据
    async getAllData() {
      const newQuery = deepClone(this.query)
      this.$set(newQuery, 'page', { pageIndex: 1, pageSize: 0 })
      const { detail, code } = await getAllData(newQuery)
      console.log('code', code)
      if (code === 0) {
        this.allData = detail
        this.btnLonding = false
      } else {
        this.$message.error('获取数据失败')
      }
    },
    // 点击当前页全选的事件
    selectAllHandler(selection) {
      // 拿到选中的数组以及要删除的数组
      const { clearArr, multipleSelection } = this
      if (!this.checkedAll) {
        // 如果全选按钮为false
        // 通过selection的长度来判断当页全选是选上还是取消
        const isDel = !selection.length
        if (isDel) {
          // 如果当页全选是false的话意思是取消了当页的所有选项
          // 先拿到当页数据的 id 组成的数组（取消的id数组）
          const ids = this.data.map(({ id }) => id)
          // 查看每个删除的id是否在选中的数组里
          ids.map((id) => {
            const fIndex = multipleSelection.findIndex((cId) => cId == id)
            //如果存在的话说明我们删除了一个在“选中”数组里的东西
            if (fIndex > -1) {
              // fIndex > -1 说明存在删除的东西在“选中”的数组里，并且fIndex为下标，把它从选中数组中去掉
              multipleSelection.splice(fIndex, 1)
            }
          })
        } else {
          // 如果当页是全选'是true'
          const ids = this.data.map(({ id }) => id)
          // 就把原来的“选中数组”与现在的选中数组拼接并且去重赋值给已“选中”的数组
          this.multipleSelection = [...new Set(this.multipleSelection.concat(ids))]
        }
        return
      }
      // 当全选按钮为true的情况下
      // 查看现在的selection的长度
      const isClear = !!selection.length
      if (isClear) {
        // 有长度说明当前页全选选择了true
        const ids = selection.map(({ id }) => id)
        // 拿到当前的selection数组的id组成的数组
        ids.map((id) => {
          // 去要取消的东西例离遍历看看有没有我们选中的数组，如果有就从我们要删除的数组中去掉那个id
          const fIndex = clearArr.findIndex((cId) => cId == id)
          if (fIndex > -1) {
            clearArr.splice(fIndex, 1)
          }
        })
      } else {
        // 如果是当页取消的话
        const ids = this.data.map(({ id }) => id)
        // 直接把他与删除的数组拼接并且去重后重新赋值给删除的数组
        this.clearArr = [...new Set(this.clearArr.concat(ids))]
      }
    },
    // 点击表格中的单选框触发事件
    selectHandler(selection, row) {
      // 如果全选按钮为false的情况下
      if (!this.checkedAll) {
        // 判断当前选择的id 是否在我们已选择数组的中
        const isExist = this.multipleSelection.includes(row.id)
        if (isExist) {
          // 如果在 说明是取消操作，就在已选中把这个id去掉
          const delIndex = this.multipleSelection.findIndex((id) => id == row.id)
          this.multipleSelection.splice(delIndex, 1)
        } else {
          // 如果不在就是选中操作，就在选中数组中添加此id
          this.multipleSelection.push(row.id)
        }
        return
      }
      // 如果全选按钮为true 的情况下
      // 判断选中的id 是否在要删除的数组中
      const isExist = this.clearArr.includes(row.id)
      if (isExist) {
        // 如果在那就是选中操作，直接把它从删除数组中去除
        const delIndex = this.clearArr.findIndex((id) => id == row.id)
        this.clearArr.splice(delIndex, 1)
      } else {
        // 如果不在那就是删除操作，直接把此id添加到删除数组中
        this.clearArr.push(row.id)
      }
    },
    // init 之后会调用的函数
    initCallBack() {
      this.$nextTick(() => {
        // 拿到选中的数组，要删除的数组，全选框的值
        const { multipleSelection, clearArr, checkedAll } = this
        // 如果 全选框为true 就拿删除的数组，否则就拿选中的数组
        const data = checkedAll ? clearArr : multipleSelection
        if (checkedAll) {
          // 如果全选为true，在当前页面数据中筛选 id 不在 删除数组中的id，返回一个数组，遍历这些数组，如果row存在就选中
          this.data
            .filter(({ id }) => !data.includes(id))
            .forEach((row) => {
              if (row) {
                this.tableRef.toggleRowSelection(row, true)
              }
            })
        } else {
          // 如果全选为false 在当前页面数据中筛选包含选中数组的中的id，返回一个数组，遍历，如果有row就选中
          this.data
            .filter(({ id }) => data.includes(id))
            .forEach((row) => {
              if (row) {
                this.tableRef.toggleRowSelection(row, true)
              }
            })
        }
      })
      // 对数据处理
      let { data } = this
      console.log('data', data)
      if (this.isMultipleReferSize && data.some((item) => item.referSizeList)) {
        let tempArr = []
        data.forEach((item) => {
          let referSizeList = item.referSizeList
          if (referSizeList) {
            let referSizeIdList = referSizeList.map(({ id }) => id)

            let moreArr = []
            let relationSizeList = [] // 已关联尺码
            let noRelationSizeList = [] // 未关联尺码
            item.sizeList?.forEach((size) => {
              if (referSizeIdList.includes(size.referSizeId)) {
                relationSizeList.push(size)
              } else {
                noRelationSizeList.push(size)
              }
            })

            referSizeList.forEach((referSize) => {
              let nItem = deepClone(item)
              nItem.referSize = referSize
              nItem.referSizeId = referSize.id

              let sizeList = relationSizeList.filter((size) => size.referSizeId === nItem.referSizeId)
              nItem.sizeList = sizeList.length ? sizeList : []

              let sizeCheckList = item.sizeCheckList?.filter((size) => size.referSizeId === nItem.referSizeId)
              nItem.sizeCheckList = sizeCheckList && sizeCheckList.length ? sizeCheckList : null

              nItem.styleList?.forEach((style) => {
                style.showImageGroupList = style.showImageGroupList?.filter(
                  (img) => img.referSizeId === nItem.referSizeId
                )
              })

              moreArr.push(nItem)
            })

            // 未关联尺码自成一列
            if (noRelationSizeList.length) {
              item.sizeList = noRelationSizeList
              moreArr.push(item)
            }

            // 合并相同列
            let moreLen = moreArr.length
            moreArr.forEach((row, index) => {
              row.$sizeList = item.sizeList
              row.$referSizeIndex = index
              row.$referSizeLen = moreLen
            })
            tempArr = tempArr.concat(moreArr)
          } else {
            item.$sizeList = item.sizeList
            tempArr.push(item)
          }
        })
        console.log(tempArr)
        this.data = tempArr
      } else {
        data.map((item) => {
          item.$sizeList = item.sizeList
          this.$set(item, 'curIndex', 0)
          return item
        })
      }
      // 拿重新所有数据
      this.getAllData()
    },
    //改变全选所有复选框,传入个不布尔值（其实就是checkAll）
    changeCheckAll(val) {
      if (val) {
        // 如果全选按钮checkAll的值为true,把当页的表格中的复选框全部勾上
        this.data.forEach((row) => {
          if (row) {
            this.tableRef.toggleRowSelection(row, true)
          }
        })
      } else {
        // 如果全选按钮checkAll的值为false,就取消当页选中的所有复选框
        this.tableRef.clearSelection()
      }
    },
    // 点击取消选择按钮触发
    async cancelClick(row) {
      this.cancelBtnLoading = true
      this.clickId = row.id
      const newQuery = {
        productPrototypeIdList: [row.id]
      }
      const { code } = await assignData(newQuery)
      if (code === 0) {
        this.$message.success('操作成功')
        this.init()
      } else {
        this.$message.error('操作失败')
      }
      this.cancelBtnLoading = false
    },
    // 合并表格
    spanMethod({ row, column }) {
      if (row.$referSizeLen) {
        if (this.referSizeSpanCol.includes(column.label)) {
          return [1, 1]
        } else {
          if (row.$referSizeIndex === 0) {
            return [row.$referSizeLen, 1]
          } else {
            return [0, 0]
          }
        }
      }
      return [1, 1]
    },
    // 添加某一列类名
    cellClassName({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 7) {
        return 'ownerNameClass'
      }
    },
    // 切换子账号时清空选择
    changeChildHandel() {
      this.checkedAll = false
      this.clearArr = []
      this.multipleSelection = []
    }
  },
  mounted() {
    this.tableRef = this.$refs.tableRef.$refs.table //拿到table的ref
  }
}
</script>

<style scoped lang="scss">
.childAccount {
  .checkBoxClass {
    margin: 10px;
  }
  .checkedAll {
    margin-top: 8px;
  }
  .merge-slot-class {
    position: relative;
    display: flex;
    align-items: center;
    .el-image {
      margin-right: 10px;
      background: $border-color;
    }
  }
  ::v-deep .ownerNameClass {
    border-right: 1px solid #ebeef5;
  }
}
</style>
